<aside
	[ngClass]="{ '-translate-x-full': !visible, 'translate-x-0': visible }"
	class="fixed top-0 left-0 z-40 max-w-70 h-screen transition-transform bg-white py-5 flex flex-col"
	appClickOutside
	(clickOutside)="onClickOutside()"
>
	<div class="px-6 pt-7">
		<img
			routerLink="/accueil"
			src="assets/images/LOGO-BBGCI-NOIR.svg"
			alt="Bridge Bank Logo"
			class="h-20 w-auto cursor-pointer"
		/>
	</div>
	<nav class="flex-grow overflow-y-auto">
		<ul class="py-2 px-6">
			<li
				*ngFor="let menuItem of menuItems; let i = index"
				[ngClass]="{
					'bg-[#EFF6FF] text-primary': activeLink === menuItem.link,
					'hover:bg-gray-100': activeLink !== menuItem.link
				}"
				(click)="setActiveLink(menuItem.link)"
				[tabindex]="0"
				(keydown)="handleKeyDown($event, menuItem.link)"
			>
				<a
					[routerLink]="menuItem.name !== 'SIDEBAR.LANGUAGE'?menuItem.link:null"
					class="flex items-center justify-between px-6 py-2"
				>
					<div class="flex items-center px-6 py-2 gap-1">
						<img
							[src]="'assets/images/' + menuItem.icon"
							[alt]="menuItem.name"
							class="w-5 h-6 mr-3"
							[ngClass]="{
								'icon-orange': activeLink === menuItem.link
							}"
						/>
						{{ menuItem.name | translate }}
					</div>
					<span
						*ngIf="menuItem.name === 'SIDEBAR.MESSAGE'"
						class="left-8 w-6 h-6 bg-primary border-2 border-white rounded-full flex items-center justify-center text-white text-xs"
					>
						{{ numberMessages }}
					</span>
					<span
						*ngIf="menuItem.name === 'SIDEBAR.LANGUAGE'"
						class="left-8"
					>
						<select (change)="setLanguage($event)" class="b-none bg-none focus:outline-none ">
							<option *ngFor="let lang of languages" [selected]="lang.value == currentLang" [value]="lang.value" class="bg-white hover:bg-primary!important">{{ lang.label | translate }}</option>
						</select>
					</span>
				</a>
			</li>
			<li class="hover:bg-gray-100">
				<button
					(click)="logout()"
					class="flex items-center px-6 py-2 gap-1 focus:outline-none pt-3"
				>
					<div class="flex items-center px-6 py-2 gap-1">
						<img
							src="assets/images/sign-out-alt.svg"
							alt="sign-out"
							class="w-5 h-6 mr-3"
						/>
						{{ 'SIDEBAR.DISCONNECT' | translate }}
					</div>
				</button>
			</li>
		</ul>
	</nav>
	<div class="p-4 flex items-center justify-center gap-6 px-6 pb-6">
		<span class="font-normal uppercase text-sm">{{
			user?.firstName + ' ' + user?.lastName
		}}</span>
		<img
			class="w-10 h-10 rounded-full"
			src="assets/images/user-picture.svg"
			alt="Rounded avatar"
		/>
	</div>
</aside>
<div *ngIf="loading$ | async" class="flex justify-center my-4">
	<app-spinner-modal [show]="true"></app-spinner-modal>
</div>
