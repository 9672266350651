import { createAction, props } from '@ngrx/store';
import { NotificationCount } from '../../domain/entities/notification.entity';

// Load Notifications
export const loadNotifications = createAction(
	'[Notification] Load Notifications'
);
export const loadNotificationsSuccess = createAction(
	'[Notification] Load Notifications Success',
	props<{ notifications: NotificationCount }>()
);
export const loadNotificationsFailure = createAction(
	'[Notification] Load Notifications Failure',
	props<{ error: any }>()
);
