import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
} from '@angular/core';
import { NgForOf, CommonModule } from '@angular/common';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { ClickOutsideDirective } from '../../../../shared/directives/clickOutside/click-outside.directive';
import { Store } from '@ngrx/store';
import * as AuthActions from '../../../../core/authentication/state/actions/auth.actions';
import { User } from '../../../../core/authentication/domain/entities/user.entity';
import {
	selectAuthSession,
	selectLoading,
} from '../../../../core/authentication/state/selectors/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { SpinnerModalComponent } from '../spinner-modal/spinner-modal.component';
import * as NotificationActions from '../../../../core/notification/state/actions/notification.actions';
import {
	selectAllNotifications,
	selectNotificationsLoading,
} from '../../../../core/notification/state/selectors/notification.selectors';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-sidebar',
	standalone: true,
	imports: [
		NgForOf,
		CommonModule,
		RouterModule,
		ClickOutsideDirective,
		SpinnerModalComponent,
		TranslateModule,
	],
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.css',
})
export class SidebarComponent implements OnInit, OnDestroy {
	@Input() visible: boolean = false;
	numberMessages: string = '2';

	activeLink: string = '/home';

	@Output() clickOutside = new EventEmitter<void>();

	user: User | undefined;

	loading$: Observable<boolean>;
	loadingNotification$: Observable<boolean>;

	languages = [
		{
			value: 'en',
			label: "SIDEBAR.LANGUAGES.ENGLISH"
		},
		{
			value: 'fr',
			label: "SIDEBAR.LANGUAGES.FRENCH"
		}
	];

	currentLang: string = "";

	private subscriptions: Subscription[] = [];

	constructor(private router: Router, private store: Store, private translate: TranslateService) {
		this.activeLink = this.router.url;
		this.loading$ = this.store.select(selectLoading);
		this.loadingNotification$ = this.store.select(selectNotificationsLoading);
		this.currentLang = this.translate.currentLang || this.translate.getDefaultLang();
	}

	

	setLanguage(event: Event): void {
		const selectedValue = (event.target as HTMLSelectElement).value;
		// Liste des langues supportées
		const supportedLangs = ['en', 'fr'];

		// Utilise la langue du navigateur si elle est supportée, sinon utilise l'anglais par défaut
		const defaultLang = supportedLangs.includes(selectedValue) ? selectedValue : 'fr';
	
		this.translate.setDefaultLang(defaultLang);  // Définit la langue par défaut
		this.translate.use(defaultLang);  // Applique la langue courante
		localStorage.setItem('selectedLanguage', defaultLang);// Enregistrer la langue dans localstorage
	}

	ngOnInit() {
		this.subscriptions.push(
			this.loading$.subscribe(),
			this.loadingNotification$.subscribe()
		);
		this.router.events.subscribe((event: any) => {
			if (event instanceof NavigationEnd) {
				this.activeLink = this.router.url;
			}
		});

		this.store.select(selectAuthSession).subscribe((authSession) => {
			this.user = authSession?.user;
		});
		this.store.dispatch(NotificationActions.loadNotifications());

		this.store.select(selectAllNotifications).subscribe((notification) => {
			this.numberMessages = notification.unreadNotificationCount;
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	menuItems = [
		{ name: 'SIDEBAR.HOME', icon: 'u_home-alt.svg', link: '/home' },
		{ name: 'SIDEBAR.MY_ACCOUNT', icon: 'bank.svg', link: '/account' },
		{
			name: 'SIDEBAR.TRANSFER',
			icon: 'virement.svg',
			link: '/transfer/history',
		},
		{
			name: 'SIDEBAR.RIB',
			icon: 'paper-svgrepo.svg',
			link: '/account/statement',
		},
		{ name: 'SIDEBAR.EXCHANGE_RATES', icon: 'u_analysis.svg', link: '/taux' },
		{ name: 'SIDEBAR.MESSAGE', icon: 'message-circle.svg', link: '/message' },
		{
			name: 'SIDEBAR.NOTIFICATION',
			icon: 'notification.svg',
			link: '/notification',
		},
		{
			name: 'SIDEBAR.HOW_TO_FIND_US',
			icon: 'localiser.svg',
			link: '/find-us',
		},
		{
			name: 'SIDEBAR.CONTACT_US',
			icon: 'customer-service-agent.svg',
			link: '/contact-us',
		},
		{
			name: 'SIDEBAR.FREQUENTLY_ASKED_QUESTIONS',
			icon: 'path17850.svg',
			link: '/faq',
		},
		{
			name: 'SIDEBAR.LANGUAGE',
			icon: 'lang.svg',
			link: '#',
		},
		{ name: 'SIDEBAR.SETTINGS', icon: 'setting.svg', link: '/settings' },
	];

	setActiveLink(link: string): void {
		this.activeLink = link;
	}

	handleKeyDown(event: KeyboardEvent, link: string) {
		if (event.key === 'Enter' || event.key === ' ') {
			this.setActiveLink(link);
		}
	}

	onClickOutside() {
		if (this.visible) {
			this.clickOutside.emit();
		}
	}

	logout() {
		this.store.dispatch(AuthActions.logout());
	}
}
